import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import SearchInput from "../../atoms/SearchInput";
import {
  LOGO,
  SITE_NAME,
  handleRedirectInternal,
  logoError,
} from "../../../Utils";
import { useHistory } from "react-router-dom";
import CustomDropdown from "../../atoms/Inputs/CustomDropdown";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import { Button } from "@material-ui/core";
import { isAuthenticated } from "../../../Utils/Auth";
import { request } from "../../../Utils/Request";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import { useCustomMediaQuery } from "../../../Utils/common";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem, List, SwipeableDrawer, Divider } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { socket } from "../../../Utils/Socket";
import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import jwtDecode from "jwt-decode";
import DialogPop from "../../atoms/DialogPop";
import PrimaryButton from "../../atoms/PrimaryButton";
import CustomDialog from "../../atoms/DialogPop/customDialog";
import { useSnackbar } from "notistack";
import ReactHtmlParser from "react-html-parser";
import CustomCheckbox from "../../atoms/Inputs/CustomCheckbox";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: "12px",
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {},
    },
  },
}))(MenuItem);

export default function Header() {
  const classes = useStyles();
  const path = window.location.pathname;
  const history = useHistory();
  const [auction_id, setAuction] = useState();
  const [routes, setRoutes] = useState([]);
  const [logourl, setLogourl] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [menuIcon, setMenuIcon] = useState(false);
  const [auctionMobile, setAuctionMobile] = React.useState(false);
  const [auctionMobileLogout, setAuctionMobileLogout] = React.useState(false);
  const [unpaidcount, setUnpiadcount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [aceept_terms, setAceptterms] = useState(false);
  const [open_term, setOpenTerms] = useState(false);
  const [pagecontent, setPage] = useState("");
  const [userdetails, setUserDetails] = useState(
    isAuthenticated() ? jwtDecode(isAuthenticated()) : [],
  );
  const [profile, setProfile] = useState({});
  const [state, setState] = React.useState({
    left: false,
  });

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    // function handleOnline() {
    //   setIsOnline(true);
    // }

    function handleOffline() {
      setIsOnline(false);
    }

    //window.addEventListener('online', handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      //window.removeEventListener('online', handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // useEffect(() => {
  //   if (window.location?.pathname?.split("/")[1] == "search") {
  //     document.body.classList.add("hideScroll");
  //   } else document.body.classList.remove("hideScroll");

  //   return () => {
  //     document.body.classList.remove("hideScroll");
  //   };
  // }, [window.location?.pathname]);

  const LogoutMobile = (anchor) => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("Userdetails");
    handleRedirectInternal(history, "login");
    toggleDrawer(anchor, false)();
  };
  const getRoutes = async (socketcall = 0) => {
    try {
      var nav_routes = [],
        key_name = isAuthenticated() ? "afterlogin" : "beforelogin";
      if (socketcall == 1) {
        var res = await request("post", "/get_nav_menu", true);
        global.navigation_details = res.data;
        res = res.data;
        localStorage.setItem("nav_details", JSON.stringify(res));
      } else {
        var res = localStorage.getItem("nav_details")
          ? JSON.parse(localStorage.getItem("nav_details"))
          : global.navigation_details;
      }
      if (res.menu.length > 0) {
        res.menu.map((data) => {
          if (data[key_name] != undefined) {
            if (parseInt(data[key_name]) == 1) {
              if (data.subLinks.length > 0) {
                if (data.subLinks[0][key_name] != undefined) {
                  data.subLinks = data.subLinks.filter(
                    (val) => parseInt(val[key_name]) == 1,
                  );
                }
              }
              nav_routes.push(data);
            }
          } else {
            nav_routes.push(data);
          }
        });
      }
      setRoutes((pre) => [...nav_routes]);
      setLogourl(res.image_path + res.logo_image);
    } catch (err) {
      console.log(err, "==> error from get dtls");
    }
  };

  useEffect(() => {
    localStorage.setItem("verified_auc", 0);
  }, []);

  useEffect(() => {
    if (
      window.location.href.includes("/search") &&
      localStorage.getItem("verified_auc") == 0
    ) {
      var auction_title_new = window.location.href.split("/search/")[1];
      if (auction_title_new.includes("?")) {
        check_auction_passwors("search/" + auction_title_new.split("?")[0]);
        localStorage.setItem("verified_auc", 1);
      } else {
        check_auction_passwors(
          "search/" + window.location.href.split("/search/")[1],
        );
        localStorage.setItem("verified_auc", 1);
      }
    }
  }, [window.location.href]);

  const check_auction_passwors = async (path) => {
    try {
      if (
        path.toString().includes("http://") ||
        path.toString().includes("https://") ||
        path.toString().includes("www")
      ) {
        if (
          path.toString().includes("www.") &&
          !path.toString().includes("http://") &&
          !path.toString().includes("https://")
        ) {
          var new_path = "https://" + path;
          window.open(new_path);
        } else {
          window.open(path);
        }
      } else {
        if (path.includes("search/")) {
          const res = await request(
            "post",
            "/check_auction_password",
            {
              auctionid: path.split("/")[1],
              auction_password: "",
            },
            true,
          );
          if (res.data.success) {
            var auction_path = res.data.title;
            history.push({
              pathname: "/search/" + auction_path,
            });
          } else {
            var auction_path = res.data.title;
            localStorage.setItem(
              "password_text",
              res.data.auction_password_text
                ? res.data.auction_password_text
                : "This auction is not yet live. Please check back later.",
            );
            history.push({
              pathname: "/check_auction_password/" + auction_path,
            });
          }
        } else {
          history.push({
            pathname: "/" + path,
          });
        }
      }
    } catch (err) {
      console.log(err, "==> error from auction password");
    }
  };

  useEffect(() => {
    socket.on("navigation_updated", (data) => {
      getRoutes(1);
    });
  }, []);

  const viewUserRef = useRef(userdetails);

  useEffect(() => {
    viewUserRef.current = userdetails;
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-QX1RPJVB7K`; // Replace with your GTM ID
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-QX1RPJVB7K"); // Replace with your GTM ID
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [window.location.href]);

  useEffect(() => {
    getRoutes();
  }, [isAuthenticated()]);

  useEffect(() => {
    if (auction_id != undefined && auction_id != "") {
      history.push({
        pathname: "/search/" + auction_id,
        state: { id: auction_id },
      });
      setMobileSearch(false);
      setAuction("");
    }
  }, [auction_id]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClickMobileMenu = () => {
    setAuctionMobile(!auctionMobile);
  };
  const handleClickMobileMenuLogout = () => {
    setAuctionMobileLogout(!auctionMobileLogout);
  };

  const list = (anchor) => (
    <div
      className={`mobile-res-header ${clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}`}
      role="presentation"
    >
      <List>
        {/* <ListItem className="Medialogomenu">
          <img
            src={logourl ? logourl : LOGO}
            onError={logoError}
            alt={SITE_NAME}
            className="site-logo-res text-center"
            onClick={() => {
              history.push("/");
              toggleDrawer(anchor, false)();
            }}
          />
        </ListItem> */}
        {routes.map((data, index) => (
          <>
            {data.type === "single" ? (
              data.path != "login" ||
              (data.path == "login" && !isAuthenticated()) ? (
                <>
                  {isAuthenticated() ? (
                    <ListItem
                      className={
                        path.includes(data.path) ? "active" : "inactive"
                      }
                      key={`${data.index}_parent`}
                      onClick={(e) => {
                        toggleDrawer(anchor, false)();
                        handleRedirectInternal(history, data.path);
                      }}
                    >
                      {data.label}
                    </ListItem>
                  ) : data.label == "Account" ? null : (
                    <ListItem
                      className={
                        path.includes(data.path) ? "active" : "inactive"
                      }
                      key={`${data.index}_parent`}
                      onClick={(e) => {
                        toggleDrawer(anchor, false)();
                        handleRedirectInternal(history, data.path);
                      }}
                    >
                      {data.label}
                    </ListItem>
                  )}
                </>
              ) : (
                ""
              )
            ) : (
              <>
                <ListItem onClick={handleClickMobileMenu}>
                  {data.label}

                  {auctionMobile ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  className="menulistitems"
                  in={auctionMobile}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {data?.subLinks.map((d, i) => (
                      <>
                        <ListItem
                          button
                          key={i}
                          onClick={(e) => {
                            toggleDrawer(anchor, false)();
                            check_auction_passwors(d.path);
                            handleRedirectInternal(history, d.path);
                          }}
                        >
                          {d.label}
                        </ListItem>
                      </>
                    ))}
                  </List>
                </Collapse>
              </>
            )}
          </>
        ))}

        {/* <ListItem
          onClick={(e) => {
            toggleDrawer(anchor, false)();
            handleRedirectInternal(history, "static/Rules");
          }}
        >
          Rules
        </ListItem> */}

        {path === "/login" ||
        path === "/register" ||
        path == "/verifyuser" ||
        path === "/login/forgot_password" ||
        path === "/forgotpassword" ? null : (
          <>
            {isAuthenticated() && (
              <>
                <List component="div" disablePadding>
                  {/* <ListItem
                    onClick={() => {
                      toggleDrawer(anchor, false)();
                      handleRedirectInternal(history, "dashboard");
                    }}
                  >
                    Dashboard
                  </ListItem> */}
                  <ListItem onClick={() => LogoutMobile(anchor)}>
                    LOGOUT
                  </ListItem>
                </List>
              </>
            )}
          </>
        )}
      </List>
    </div>
  );

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const sumit_popup = async () => {
    try {
      if (!aceept_terms) {
        enqueueSnackbar("Please Accept Terms And Conditions!", {
          variant: "error",
          preventDuplicate: true,
        });
        return false;
      }
      const res_pop = await request(
        "post",
        "/bddw_updateExtendedBidPopup",
        {
          site_id: process.env.REACT_APP_SITE_ID,
          user_id: profile.id,
          terms_check: 2,
        },
        true,
      );
      if (res_pop.data.status) {
        enqueueSnackbar("Updated Successfully!", {
          variant: "success",
          preventDuplicate: true,
        });
        setOpenTerms(false);
        setAceptterms(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserdetails = async () => {
    try {
      const res = await request("post", "/getUserProfileDetails", {}, true);
      if (res.data.success === "yes") {
        var user = res.data.data;
        const { instagram, goldenAckw, goldenRule } = user.company_name
          ? JSON.parse(user.company_name)
          : {};
        setProfile({
          first_name: user.first_name,
          last_name: user.last_name,
          user_name: user.username,
          email: user.email,
          mobile_number: !user.phone.includes("+")
            ? "+" + user.phone
            : user.phone,
          address1: user.address1,
          address2: user.address2,
          city: user.city,
          state: user.state,
          zip_code: user.zip,
          country: user.country,
          customer_id: user.card_paymentid,
          instagram: instagram,
          goldenAckw: goldenAckw,
          goldenRule: goldenRule,
          bddw_sms_consent: parseInt(user.bddw_sms_consent) == 0 ? false : true,
          id: user.id,
          otpnumber: "",
        });
        // if (parseInt(user.terms_check) == 1) {
        //   setOpenTerms(true);
        // }

        // if (parseInt(user.bddw_sms_consent) == 0) {
        //   setOtppopup(true)
        // }
      } else {
        if (
          res.data?.failedreason ===
          "Your account status is not active. Please contact BDDW Admin."
        ) {
          localStorage.removeItem("userToken");
          localStorage.removeItem("Userdetails");
          enqueueSnackbar("YOUR ACCOUNT DEACTIVATED BY ADMIN NOW!", {
            variant: "error",
            preventDuplicate: true,
          });
          window.location.href = "/login";
        }
      }
      //viewAllCard(user.card_paymentid);
    } catch (error) {
      console.log(error, "this is from the user details");
    }
  };

  useEffect(() => {
    checkTermsFlagReset();

    getUserdetails();
  }, [isAuthenticated()]);

  useEffect(() => {
    const lastDeploymentVersion = localStorage.getItem("deploymentVersion")
      ? localStorage.getItem("deploymentVersion")
      : 0;
    const currentVersion = process.env.REACT_APP_VERSION
      ? process.env.REACT_APP_VERSION
      : 0;

    if (parseInt(lastDeploymentVersion) !== 0) {
      if (parseInt(lastDeploymentVersion) !== parseInt(currentVersion)) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("Userdetails");
        localStorage.setItem("deploymentVersion", currentVersion);
        handleRedirectInternal(history, "login");
      }
    }
  }, []);
  const call_staticpages = async () => {
    const res = await request("post", "/show_static_content", {
      page_id: "Extended-terms",
    });
    setPage(res.data.data[0].content);
  };
  useEffect(() => {
    call_staticpages();
  }, []);

  const getunpaidcount = async () => {
    try {
      const res = await request(
        "post",
        "/bddw_getUnpaidCount",
        { site_id: "AS0242", user_id: userdetails?.id },
        true,
      );
      if (res.data.status) {
        setUnpiadcount(res.data.total_count);
        localStorage.setItem("unpaid_count", res.data.unpaid_count);
        localStorage.setItem("pending_count", res.data.pending_count);
      } else {
        setUnpiadcount(0);
        localStorage.setItem("unpaid_count", 0);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (userdetails?.id) {
      getunpaidcount();
    }
  }, [userdetails]);

  const checkTermsFlagReset = async () => {
    try {
      const res = await request("post", "/bddw_checkTermsFlagReset", {}, true);
      if (res.data.status) {
        setOpenTerms(true);
      } else {
      }
    } catch (error) {
      console.log(error, "check terms api");
    }
  };

  useEffect(() => {
    socket.on("bddw_termsFlagReset", (data) => {
      checkTermsFlagReset();
    });
    socket.on("allowBidderStatusChange", (data) => {
      var user_details_new = viewUserRef.current;
      if (parseInt(userdetails?.id) == parseInt(data.user_id)) {
        user_details_new.allow_bidder = data.allow_bidder_flag;
      }
      setUserDetails(user_details_new);
      localStorage.setItem("bidder_status", parseInt(data.allow_bidder_flag));
    });
    socket.on("bddw_unpaidCntApiTrigger", (data) => {
      var user_details_unpaid = viewUserRef.current;
      if (parseInt(user_details_unpaid.id) == parseInt(data.userid)) {
        if (window.location.href.includes("/dashboard")) {
          window.location.reload();
        } else {
          getunpaidcount();
        }
      }
    });
    return () => {
      socket.off("allowBidderStatusChange", (data) => {
        var user_details_new = viewUserRef.current;
        if (parseInt(userdetails?.id) == parseInt(data.user_id)) {
          user_details_new.allow_bidder = data.allow_bidder_flag;
        }
        setUserDetails(user_details_new);
        localStorage.setItem("bidder_status", parseInt(data.allow_bidder_flag));
      });
      socket.off("bddw_unpaidCntApiTrigger", (data) => {
        var user_details_unpaid = viewUserRef.current;
        if (parseInt(user_details_unpaid.id) == parseInt(data.userid)) {
          if (window.location.href.includes("/dashboard")) {
            window.location.reload();
          } else {
            getunpaidcount();
          }
        }
      });
    };
  }, []);

  return (
    <>
      {useCustomMediaQuery("(max-width: 1024px)") ? (
        <>
          <div className="container sidedrawer-header">
            <div className="sidedrawer-menues">
              {["left"].map((anchor) => (
                <React.Fragment>
                  <Button aria-label="Menu">
                    <MenuIcon onClick={toggleDrawer(anchor, true)} />
                  </Button>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
            <div
              className={`site-mobile-logo ${
                path === "/" ||
                path === "/login" ||
                path === "/register" ||
                path === "/verifyuser" ||
                path === "/forgotpassword" ||
                path === "/login/forgot_password"
                  ? "ml0"
                  : "ml0"
              }`}
            >
              {/* <img
                src={logourl ? logourl : LOGO}
                alt={SITE_NAME}
                width={130}
                height={60}
                className="site-logo"
                onError={logoError}
                onClick={() => {
                  history.push("/");
                }}
              /> */}
              {/* {isAuthenticated() ? (
                  <CustomDropdown
                    icon={<AccountCircleOutlinedIcon />}
                    arrow={<ArrowDropDownOutlinedIcon />}
                    type="mobile"
                  />
                ) : (
                  ""
                )} */}
            </div>
            <>
              {path === "/" ||
              path === "/login" ||
              path === "/register" ||
              path === "/verifyuser" ||
              path === "/forgotpassword" ||
              path === "/login/forgot_password" ? (
                <div style={{ visibility: "hidden" }}></div>
              ) : !mobileSearch && width <= "1024" ? (
                <>
                  <div style={{ visibility: "hidden" }}></div>
                  {/* {menuIcon ? (
                    <CloseIcon onClick={() => setMenuIcon(!menuIcon)} />
                  ) : (
                    <SearchIcon onClick={() => setMenuIcon(!menuIcon)} />
                  )} */}
                </>
              ) : width >= "1024" ? (
                path === "/" ||
                path === "/login" ||
                path === "/register" ||
                path === "/verifyuser" ||
                path === "/forgotpassword" ||
                path === "/login/forgot_password" ? null : null
              ) : (
                // (
                //   <div className="tab-search">
                //     <SearchInput
                //       onChange={(value) => {
                //         setAuction("Lot-" + value);
                //       }}
                //     />
                //   </div>
                // )
                <div style={{ visibility: "hidden" }}></div>
              )}
            </>
          </div>

          {
            path === "/" ||
            path === "/login" ||
            path === "/register" ||
            path === "/verifyuser" ||
            path === "/forgotpassword" ||
            path === "/login/forgot_password"
              ? null
              : null
            // menuIcon && (
            //     <div className="mobile-search-view">
            //       <SearchInput
            //         onChange={(value) => {
            //           setAuction("Lot-" + value);
            //         }}
            //       />
            //     </div>
            //   )
          }
        </>
      ) : (
        <div className="customContainer">
          <div
            className={`header ${
              path == "/" ||
              path == "/login" ||
              path == "/auction" ||
              path == "/register"
                ? ""
                : ""
            }`}
          >
            <>
              {
                path === "/" ||
                path === "/login" ||
                path === "/register" ||
                path === "/verifyuser" ||
                path === "/forgotpassword" ||
                path === "/login/forgot_password"
                  ? null
                  : null
                // (
                //   <SearchInput
                //     onChange={(value) => {
                //       setAuction("Lot-" + value);
                //     }}
                //   />
                // )
              }
            </>
            {/* <img
              src={logourl ? logourl : LOGO}
              onError={logoError}
              alt={SITE_NAME}
              width={150}
              height={60}
              className="site-logo"
              onClick={() => history.push("/")}
            /> */}
            {path === "/login" ||
            path === "/register" ||
            path == "/verifyuser" ||
            path === "/login/forgot_password" ||
            path === "/forgotpassword" ? null : (
              <div className="avathar">
                {isAuthenticated() ? (
                  <CustomDropdown
                    icon={<AccountCircleOutlinedIcon />}
                    arrow={<ArrowDropDownOutlinedIcon />}
                    unpaidcount={unpaidcount}
                    profile={profile}
                    type="desktop"
                  />
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="navItems">
              <div className="row auctionNav">
                <div className="col-md-9 col-sm-12 col-xs-12 myaccount-btn">
                  {routes.map((data, index) => (
                    <>
                      {data.type === "single" ? (
                        data.path != "login" ||
                        (data.path == "login" && !isAuthenticated()) ? (
                          <>
                            {isAuthenticated() ? (
                              <Button
                                className={
                                  path.includes(data.path)
                                    ? "active"
                                    : "inactive"
                                }
                                key={`${data.index}_parent`}
                                onClick={(e) =>
                                  handleRedirectInternal(history, data.path)
                                }
                              >
                                {data.label}
                              </Button>
                            ) : data.label == "Account" ? null : (
                              <Button
                                className={
                                  path.includes(data.path)
                                    ? "active"
                                    : "inactive"
                                }
                                key={`${data.index}_parent`}
                                onClick={(e) =>
                                  handleRedirectInternal(history, data.path)
                                }
                              >
                                {data.label}
                              </Button>
                            )}
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        <PopupState variant="popover" popupId="demoPopover">
                          {(popupState) => (
                            <div>
                              <Button
                                {...bindHover(popupState)}
                                onClick={(e) =>
                                  handleRedirectInternal(history, data.path)
                                }
                              >
                                {data.label}
                              </Button>
                              <HoverPopover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                {data.subLinks.map((d, i) => (
                                  <>
                                    <StyledMenuItem
                                      className="customMenu"
                                      onClick={(e) => {
                                        check_auction_passwors(d.path);
                                      }}
                                    >
                                      {d.label}
                                    </StyledMenuItem>
                                  </>
                                ))}
                              </HoverPopover>
                            </div>
                          )}
                        </PopupState>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <DialogPop
        //onClose={(e) => setOtppopup(false)}
        open={!isOnline}
        title="Connection Disrupted"
        nobutton={1}
        hideHeader={1}
      >
        <div className="dialogBody">
          <form className="regFrom my-3 pb-4 pt-4">
            <div className="container">
              <p
                className="mb-2 text-center"
                style={{ fontSize: "20px", textTransform: "none" }}
              >
                Weak internet connection. Please refresh the page.
              </p>

              <div className="flexOtpBtns">
                <PrimaryButton
                  label={"REFRESH"}
                  onClick={(e) => {
                    setIsOnline(true);
                    window.location.reload();
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </DialogPop>
      <div>
        <CustomDialog
          //onClose={highBidClose}
          open={
            open_term &&
            path != "/login" &&
            path != "/register" &&
            path != "/verifyuser" &&
            path != "/login/forgot_password" &&
            path != "/forgotpassword" &&
            isAuthenticated()
          }
          maxWidth="md"
          fullWidth={false}
        >
          <div className="proxy-bid-content proxy-custom">
            {ReactHtmlParser(pagecontent)}
            <CustomCheckbox
              label={<p>I CONFIRM THAT I HAVE READ AND UNDERSTAND.</p>}
              labelPlacement="end"
              value="termsCondition"
              name="termsCondition"
              checked={aceept_terms}
              onchange={(e) => setAceptterms(e.target.checked)}
              // onBlur={formik.handleBlur}
            />
            <PrimaryButton
              onClick={() => sumit_popup()}
              type={"submit"}
              label={"Submit"}
            />
          </div>
        </CustomDialog>
      </div>
    </>
  );
}
