import { isAuthenticated } from "./Auth";

export const LOGO = "/assets/images/bddwlogo.jpg";
export const SITE_NAME = "BDDW Auctions";

export const handleRedirectInternal = (history, path) => {
  if (
    path.toString().includes("http://") ||
    path.toString().includes("https://") ||
    path.toString().includes("www")
  ) {
    if (
      path.toString().includes("www.") &&
      !path.toString().includes("http://") &&
      !path.toString().includes("https://")
    ) {
      var new_path = "https://" + path;
      window.open(new_path);
    } else {
      window.open(path);
    }
  } else {
    if (path == "dashboard" && !isAuthenticated()) {
      path = "login";
    }
    history.push(`/${path}`);
    window.scrollTo(0, 0);
  }
};
export const onImageError = (e, status = "", ava = "", error_count = 0) => {
  if (status == "open" && error_count == 1 && ava) {
    e.target.src = ava;
  } else if (status == "open" && error_count == 2) {
    e.target.src = "/assets/images/bddwlogo.svg";
  } else {
    e.target.src = "/assets/images/bddwlogo.svg";
  }
};

export const logoError = (e) => (e.target.src = LOGO);
export const getnearteImages = (array, enable = 0) => {
  let product = array.map((data) => {
    let images = [];
    let ava = "";
    if (data.lotImages.length !== 0) {
      //ava = `${process.env.REACT_APP_PRODUCT_IMG}${process.env.REACT_APP_SITE_ID}/${data.auctionlot_title}/${data.productImagesName[0]}`
      ava = data?.market_status
        ? imageToImagekitURL(data.lotImages[0].file_name, data?.market_status)
        : data.lotImages[0].file_name;
      images = data.lotImages.map((img) => {
        //let image = `${process.env.REACT_APP_PRODUCT_IMG}${process.env.REACT_APP_SITE_ID}/${data.auctionlot_title}/${img}`
        if (img.file_name) {
          // let image = img.file_name;

          let image = data?.market_status
            ? imageToImagekitURL(img.file_name, data?.market_status)
            : img.file_name;
          // if (
          //   !img.file_name.toString().includes("amazon") &&
          //   !img.file_name.toString().includes("bddwauctions")
          // ) {
          //   ava = `${process.env.REACT_APP_PRODUCT_IMG}/${data.lotImages[0].file_name}`;
          //   image = `${process.env.REACT_APP_PRODUCT_IMG}/${img.file_name}`;
          // }
          return { original: image, thumbnail: image };
        }
      });
    } else if (data.productImagesName.length !== 0) {
      ava = `${process.env.REACT_APP_PRODUCT_IMG}${process.env.REACT_APP_SITE_ID}/${data.auctionlot_title}/${data.productImagesName[0]}`;
      //ava=data.lotImages[0].file_name
      images = data.productImagesName.map((img) => {
        let image = `${process.env.REACT_APP_PRODUCT_IMG}${process.env.REACT_APP_SITE_ID}/${data.auctionlot_title}/${img}`;
        //let image =img.file_name
        return { original: image, thumbnail: image };
      });
    } else {
      images = [{ original: "", thumbnail: "" }];
    }
    if (enable == 1) {
      return {
        ...data,
        productImages: images,
        productAva: ava,
        isFav: "1",
        img_error_count: 0,
      };
    } else {
      return {
        ...data,
        productImages: images,
        productAva: ava,
        img_error_count: 0,
      };
    }
  });
  return product;
};

export const imageToImagekitURL = (inputURL, marketStatus) => {
  const dynamicPartMatch = inputURL.match(
    /https:\/\/([^\.]+)\.s3\.us-east-2\.amazonaws\.com/,
  );
  const baseImageUrl = "https://ik.imagekit.io/6s8p3m2gj";

  if (marketStatus === "open" && inputURL) {
    return dynamicPartMatch
      ? inputURL.replace(dynamicPartMatch[0], baseImageUrl)
      : inputURL
          .replace(
            "https://auction-software.s3.amazonaws.com/bddw",
            baseImageUrl,
          )
          .replace(
            "https://auc-weekly.s3.us-east-2.amazonaws.com",
            baseImageUrl,
          )
          .replace("https://bddwinventory.com/assets/large", baseImageUrl);
  }

  return inputURL;
};
