import React, { useState, useEffect } from "react";
import { isAuthenticated } from "./Auth";
import jwtDecode from "jwt-decode";
import moment from "moment";
export const callNumber = (num) => {
  return `${
    "$" + new Intl.NumberFormat("us", { minimumFractionDigits: 2 }).format(num)
  }`;
};
export const bidstatus = (userid, nobids, status, award_user_id) => {
  const user = isAuthenticated() ? jwtDecode(isAuthenticated()) : "";
  if (nobids == 0 || !isAuthenticated() || nobids == null) {
    return "";
  } else if (userid == user.id) {
    if (status == "open") {
      return "WINNING";
    } else {
      if (award_user_id) {
        if (award_user_id == user.id) {
          return "WON";
        } else {
          return "LOST";
        }
      } else {
        return "PENDING";
      }
    }
  } else {
    if (status == "open") {
      return "OUTBID";
    } else {
      if (award_user_id) {
        if (award_user_id == user.id) {
          return "WON";
        } else {
          return "LOST";
        }
      } else {
        return "PENDING";
      }
    }
  }
};
export const TimetoSeconds = (date1, date2) => {
  const timeVariation = moment
    .duration(moment(new Date(date1)).diff(moment(new Date(date2))))
    .asSeconds();
  return Math.floor(timeVariation);
};
export const Phone = (e) => {
  console.log("+++++++++++++++", e.which);
  if (
    (e.which !== 8 && e.which !== 0 && e.which < 48 && e.which !== 43) ||
    e.which > 57
  )
    e.preventDefault();
};
export const useCustomMediaQuery = (queryString) => {
  const [isMatch, setIsMatch] = useState(false);
  const mqChange = (mq) => {
    setIsMatch(mq.matches);
  };

  useEffect(() => {
    const mq = window.matchMedia(queryString);
    mq.addListener(mqChange);
    mqChange(mq);

    return () => {
      mq.removeListener(mqChange);
    };
  }, [queryString]);

  return isMatch;
};

export const bidtext = (status) => {
  if (status == "open") {
    return "Current high bid : ";
  } else {
    return "Winning bid : ";
  }
};
export const selectclassname = (status) => {
  if (status == "open") {
    return "";
  } else {
    return "auction_closed";
  }
};
